import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout' 
import useSiteMetadata from "../components/SiteMetadata";
import publisherImg from '../img/schemapublisher.png'
import BodyContentRender from "../components/BodyContentRender";
import Img from "gatsby-image";
import ContactForm from "../components/ContactForm";
import SEO from '../components/SEO'
import Breadcrumbs from '../components/Breadcrumbs'


const BlogPost = ({ data }) => {
  const {
    websiteurl,
    companyname
  } = useSiteMetadata();

  const { markdownRemark: post } = data 

  let tags = []
  if(post.frontmatter.tags) {
    tags = post.frontmatter.tags
  } 

  
  return (
    <Layout bottomBorder={true}>

        <SEO
        title={post.frontmatter.title} 
        description={post.frontmatter.description}
        img={post.frontmatter.featuredimage.childImageSharp.fixed.src} 
      />
      <div className="bg-white border-b border-gray-100">
      <nav className="flex container mx-auto px-4 py-3" aria-label="Breadcrumb">

        <Breadcrumbs links={[{ to: '/', label: 'Home' }, { to: '/blog', label: 'Blog' },  { to: `${post.fields.slug}`, label: post.frontmatter.title }]} />

      </nav>
    </div>

      <section className="section px-4 md:px-6 bg-gray-50">
      <Helmet >
            
            <script type="application/ld+json">{`
                  {
                  "@context": "http://schema.org",
                  "@type": "BlogPosting",
                  "mainEntityOfPage":{
                  "@type":"WebPage",
                  "@id": "${websiteurl + post.fields.slug}"
                  },
                  "headline": ${post.frontmatter.title},
                  "image": {
                    "@type": "ImageObject",
                    "url": "${websiteurl + post.frontmatter.featuredimage.childImageSharp.fixed.src}",
                    "height": 600,
                    "width": 800
                  },
                  "datePublished": "${post.frontmatter.date}",
                  "dateModified": "${post.frontmatter.date}",
                  "author": {
                    "@type": "Person",
                    "name": "${post.frontmatter.author.frontmatter.name}"
                  },
                  "publisher": {
                    "@type": "Organization",
                    "name": "${companyname}",
                    "logo": {
                      "@type": "ImageObject",
                      "url": "${publisherImg}",
                      "width": 600,
                      "height": 60
                    }
                  },
                  "description": "${post.frontmatter.description}"
                  }
                  `}</script>
          </Helmet>
          <div className="flex flex-wrap container px-4 md:px-6 mx-auto py-10">

            <div className="w-full md:w-2/3 p-0 md:pr-16">
              <h1 className="text-xl sm:text-2xl md:text-4xl mb-2 font-bold">{post.frontmatter.title}</h1>
            <span className="text-gray-800 mb-8 flex items-center" >
              <svg className="mr-2" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <title>disabled--inverted</title><g fill="#AAB7C4" fill-rule="evenodd"><circle fill-opacity=".2" cx="10" cy="10" r="10"/><path d="M10 20a9.964 9.964 0 0 1-7.25-3.125c1.063-1.95 3.306-3.262 7.25-3.262s6.188 1.312 7.25 3.262A9.965 9.965 0 0 1 10 20zm.004-16.231l.001-.002c2.077 0 3.759 1.507 3.759 3.957s-1.683 3.962-3.76 3.962c-2.076 0-3.76-1.508-3.76-3.958s1.685-3.96 3.76-3.96z"/></g>
                </svg>
                {`${post.frontmatter.author.frontmatter.name} on ${post.frontmatter.dateReadable}`}</span>
 
        <Img
              fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
              alt={post.frontmatter.title}
              className="shadow-lg w-full object-cover mb-8 mt-0 border-4 border-white"
              style={{
                height: '300px',
                objectFit: 'cover', 
              }}
            />
            <div className="prose">
           

            <BodyContentRender markdown={post.html}   /> 

            </div>
    
            </div>
          
            
            


        <div className="w-full md:w-1/3 p-0 md:pl-6">
          <div className="sticky top-0  z-1 ">
            <div className="h-2"></div>
          <div className="bg-white shadow-xl rounded-xl p-6 rounded-x mt-2">   
          <h2 className="font-semibold text-xl text-center mb-2">Start with a FREE Quote</h2>
          <ContactForm hideLabels={true} showPlaceholders={true} footerContained={true}/>
          </div>
          </div>
        
     
        </div>
        
          </div>
       

          {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          
      </section> 
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        dateReadable: date(formatString: "MMMM DD, YYYY")
        title
        description
        tags  
        featuredimage {
          childImageSharp { 
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 800, height: 600, quality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        author {
          frontmatter {
            name
          }
        } 
      }
    }
  }
`
